import request from '@/utils/request'

const hostCorporationApi = {
  trade_host_corporation_list: '/trade/host_corporation/',
  trade_host_corporation_create: '/trade/host_corporation/',
  trade_host_corporation_update: '/trade/host_corporation/',
  trade_host_corporation_delete: '/trade/host_corporation/'
}

/**
 * 列表
 */
export function corporation_list (parameter) {
  return request({
    url: hostCorporationApi.trade_host_corporation_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function corporation_create (parameter) {
  return request({
    url: hostCorporationApi.trade_host_corporation_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function corporation_update (parameter, corporation_id) {
  return request({
    url: hostCorporationApi.trade_host_corporation_update + corporation_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function corporation_delete (corporation_id) {
  return request({
    url: hostCorporationApi.trade_host_corporation_delete + corporation_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
