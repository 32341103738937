<template>
  <el-container>
    <el-header>
      <layout-header v-model="activeSelect" @publish="publishProcess" @preview="preview" @input="changeMenu"></layout-header>
    </el-header>
    <div class="layout-body">
      <form-base-setting ref="baseSetting" v-show="activeSelect[0] === 'baseSetting'"/>
      <!--<form-design ref="formSetting" v-show="activeSelect === 'formSetting'" />-->
      <process-design ref="processDesign" v-show="activeSelect[0] === 'processDesign'" />
      <!--<form-pro-setting ref="proSetting" v-show="activeSelect === 'proSetting'" />-->
    </div>
    <w-dialog :showFooter="false" v-model="validVisible" title="设置项检查">
      <el-steps align-center :active="validStep" finish-status="success">
        <el-step
          v-for="(step, i) in validOptions"
          :title="step.title"
          :key="i"
          :icon="step.icon"
          :status="step.status"
          :description="step.description" />
      </el-steps>
      <el-result :icon="validIcon" :title="errTitle" :subTitle="validResult.desc">
        <i slot="icon" style="font-size: 30px" v-if="!validResult.finished" class="el-icon-loading"></i>
        <div slot="subTitle" class="err-info" v-if="validResult.errs.length > 0">
          <e-ellipsis hover-tip v-for="(err, i) in validResult.errs" :key="i + '_err'" :content="err">
            <i slot="pre" class="el-icon-warning-outline"></i>
          </e-ellipsis>
        </div>
        <template slot="extra">
          <el-button type="primary" v-if="validResult.finished" size="medium" @click="doAfter">
            {{ validResult.action }}
          </el-button>
        </template>
      </el-result>
    </w-dialog>
  </el-container>

</template>

<script>
import LayoutHeader from './LayoutHeader'
import { EEllipsis, WDialog } from '@/components'
import { getForms, createForm, updateFormDetail } from '@/api/trade_design'
import FormBaseSetting from '@/views/a-trade/approve/admin/layout/FormBaseSetting'
import FormDesign from '@/views/a-trade/approve/admin/layout/FormDesign'
import ProcessDesign from '@/views/a-trade/approve/admin/layout/ProcessDesign'
import FormProSetting from '@/views/a-trade/approve/admin/layout/FormProSetting'

export default {
  name: 'FormProcessDesign',
  components: { EEllipsis, LayoutHeader, FormBaseSetting, FormDesign, ProcessDesign, FormProSetting, WDialog },
  data () {
    return {
      isNew: true,
      validStep: 0,
      timer: null,
      activeSelect: ['baseSetting'],
      validVisible: false,
      validResult: {},
      validOptions: [
        { title: '基础信息', description: '', icon: '', status: '' },
        { title: '审批流程', description: '', icon: '', status: '' }
      ],
      validComponents: ['baseSetting', 'processDesign']
    }
  },
  computed: {
    setup () {
      return this.$store.state.shenpi.design
    },
    errTitle () {
      if (this.validResult.finished && !this.validResult.success) {
        return this.validResult.title + ` (${this.validResult.errs.length}项错误) 😥`
      }
      return this.validResult.title
    },
    validIcon () {
      if (!this.validResult.finished) {
        return 'el-icon-loading'
      } else if (this.validResult.success) {
        return 'success'
      } else {
        return 'warning'
      }
    }
  },
  created () {
    this.showValiding()
    const formId = this.$route.query.code
    // 判断传参，决定是新建还是加载原始数据
    this.loadInitFrom()
    if (this.$isNotEmpty(formId)) {
      this.isNew = false
      this.loadFormInfo(formId)
    }
    const group = this.$route.query.group
    this.setup.groupId = this.$isNotEmpty(group) ? parseInt(group) : null
  },
  beforeDestroy () {
    this.stopTimer()
  },
  methods: {
    loadFormInfo (formId) {
      getForms({ id: formId }).then(rsp => {
        console.log(rsp.data)
        const form = rsp.data.entries[0]
        console.log('这是获取到的数据，index是第一个', form)
        // form.logo = JSON.parse(form.logo)
        form.settings = JSON.parse(form.settings)
        // form.formItems = JSON.parse(form.formItems)
        form.process = JSON.parse(form.process)
        form.formId = form.id
        this.$store.commit('loadForm', form)
      }).catch(err => {
        this.$message.error(err)
      }).finally(() => {
        console.log('=============>>>', this.isNew || !this.$isNotEmpty(this.setup.formId))
      })
    },
    loadInitFrom () {
      this.$store.commit('loadForm', {
        formId: null,
        template_name: '',
        logo: {
          icon: 'el-icon-s-management',
          background: '#1e90ff'
        },
        settings: {
          commiter: [],
          admin: [],
          sign: false,
          notify: {
            types: [],
            title: '消息通知标题'
          }
        },
        groupId: undefined,
        formItems: [],
        process: {
          id: 'root',
          parentId: null,
          type: 'ROOT',
          name: '发起人',
          desc: '任何人',
          props: {
            assignedUser: [],
            formPerms: []
          },
          children: {}
        },
        remark: ''
      })
    },
    validateDesign () {
      // this.validVisible = true
      this.validStep = 0
      // this.showValiding()
      this.stopTimer()
      this.timer = setInterval(() => {
        this.validResult.errs = this.$refs[this.validComponents[this.validStep]].validate()
        if (Array.isArray(this.validResult.errs) && this.validResult.errs.length === 0) {
          this.validStep++
          if (this.validStep >= this.validOptions.length) {
            this.stopTimer()
            // this.showValidFinish(true)
            this.doPublish()
          }
        } else {
          this.stopTimer()
          this.validOptions[this.validStep].status = 'error'
          // this.showValidFinish(false, this.getDefaultValidErr())
          this.$message.error(this.getDefaultValidErr())
        }
      }, 300)
    },
    getDefaultValidErr () {
      switch (this.validStep) {
        case 0:
          return '请检查基础信息'
        case 1:
          return '请检查审批表单相关设置'
        case 2:
          return '请检查审批流程，查看对应标注节点错误信息'
        case 3:
          return '请检查扩展设置'
        default:
          return '未知错误'
      }
    },
    showValidFinish (success, err) {
      this.validResult.success = success
      this.validResult.finished = true
      this.validResult.title = success ? '校验完成 😀' : '校验失败 '
      this.validResult.desc = success ? '设置项校验成功，是否提交？' : err
      this.validResult.action = success ? '提 交' : '去修改'
    },
    showValiding () {
      this.validResult = {
        errs: [],
        finished: false,
        success: false,
        title: '检查中...',
        action: '处理',
        desc: '正在检查设置项'
      }
      this.validStep = 0
      this.validOptions.forEach(op => {
        op.status = ''
        op.icon = ''
        op.description = ''
      })
    },
    doAfter () {
      if (this.validResult.success) {
        this.doPublish()
      } else {
        this.activeSelect = [this.validComponents[this.validStep]]
        this.validVisible = false
      }
    },
    stopTimer () {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    preview () {
      this.validateDesign()
    },
    publishProcess () {
      this.validateDesign()
    },
    changeMenu (menu) {
      this.activeSelect = [menu]
    },
    doPublish () {
      this.$confirm('发布后流程立即生效，相同分类其他流程会停用，是否继续?', '提示', {
        confirmButtonText: '发布',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(this.setup)
        const processNew = JSON.parse(JSON.stringify(this.setup.process))

        // 判断条件分支
        this.conditionRecursion(processNew)

        const template = {
          // formId: this.setup.formId,
          template_name: this.setup.template_name,
          // logo: JSON.stringify(this.setup.logo),
          settings: JSON.stringify(this.setup.settings),
          host_corporation_id: this.setup.host_corporation_id,
          // formItems: JSON.stringify(this.setup.formItems),
          process: JSON.stringify(processNew),
          remark: this.setup.remark,
          template_type: this.setup.settings.notify.types
        }
        if (this.isNew || !this.$isNotEmpty(this.setup.formId)) {
          createForm(template).then(rsp => {
            this.$message.success('创建表单成功')
            // this.$router.push('/tools/tool/shenpi')
            this.$router.back()
          }).catch(err => {
            this.$message.error(err)
          })
        } else {
          updateFormDetail(template, this.setup.formId).then(rsp => {
            if (rsp.code === 1000) {
              this.$message.success('更新成功')
            } else {
              this.$message.success('更新失败')
            }
            // this.$router.push('/tools/tool/shenpi')
            this.$router.back()
          }).catch(err => {
            this.$message.error(err)
          })
        }
      })
    },
    conditionRecursion (process) {
      if (process != null) {
        if (process.branchs != null) {
          process.branchs.map((item, i) => {
            if (i === process.branchs.length - 1) {
              item.typeElse = true
            } else {
              item.typeElse = false
            }
            if (item.children != null) {
              this.conditionRecursion(item.children)
            } else {
              return item
            }
          })
        } else {
          this.conditionRecursion(process.children)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>

.layout-body {
  min-width: 980px;
}

/deep/ .el-step {
  .is-success {
    color: #2a99ff;
    border-color: #2a99ff;
  }
}

.err-info {
  max-height: 180px;
  overflow-y: auto;

  & > div {
    padding: 5px;
    margin: 2px 0;
    width: 220px;
    text-align: left;
    border-radius: 3px;
    background: rgb(242 242 242);
  }

  i {
    margin: 0 5px;
  }
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #e8e8e8;
}

</style>
