<template>
  <div class="base-setup" @click="showIconSelect = false">
    <el-form ref="baseSetting" :model="setup" label-position="top" label-width="80px">
      <el-form-item label="主体企业" :rules="getRule('主体企业')" v-if="this.$route.query.code !== undefined">
        <span>{{ setup.host_corporation }}</span>
      </el-form-item>
      <el-form-item label="主体企业" :rules="getRule('请选择主体企业')" v-if="this.$route.query.code === undefined">
        <a-select
          v-model="setup.host_corporation_id"
          option-label-prop="tt"
          :filter-option="false"
          show-search
          allow-clear
          placeholder="请选择主体企业"
          :not-found-content="loading ? undefined : null"
          @search="fetchHostCorporation">
          <a-spin v-if="loading" slot="notFoundContent" size="small" />
          <a-select-option v-for="(wc, index) in hostCorporation" :tt="wc.name" :key="index" :value="wc.id">
            {{ wc.name }}
          </a-select-option>
        </a-select>
      </el-form-item>
      <el-form-item label="审批名称" :rules="getRule('请输入审批名称')" prop="template_name">
        <a-input v-model="setup.template_name" size="default" placeholder="请输入名称" :max-length="18"></a-input>
      </el-form-item>
      <el-form-item label="所属分类" :rules="getRule('请选择审批所属分类')">
        <a-select
          v-model="setup.settings.notify.types"
          option-label-prop="tt"
          placeholder="选择审批所属分类"
          allow-clear>
          <a-select-option v-for="(value, key, index) in this.$Dictionaries.trade_approve_type" :tt="value" :key="index" :value="key">
            {{ value }}
          </a-select-option>
        </a-select>
        <a-input
          v-if="false"
          v-model="setup.settings.notify.title"
          style="width: 68%; float:right;"
          placeholder="消息通知标题"></a-input>
      </el-form-item>
      <el-form-item label="表单说明">
        <a-textarea
          placeholder="请输入表单说明"
          :auto-size="{ minRows: 4, maxRows: 5 }"
          v-model="setup.remark">
        </a-textarea>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { updateGroup } from '@/api/design'
import { corporation_list } from '@/api/trade_corporation'
import debounce from 'lodash/debounce'

export default {
  name: 'FormBaseSetting',
  data () {
    this.fetchHostCorporation = debounce(this.fetchHostCorporation, 800)
    return {
      loading: false,
      nowUserSelect: null,
      select: [],
      newGroup: '',
      hostCorporation: [],
      notifyTypes: [
        { type: 'trade_sell_contract', name: '购销合同' },
        { type: 'trade_contract', name: '普通合同' },
        { type: 'trade_inventory', name: '库存' },
        { type: 'trade_inventory_adjustment', name: '库存调整' },
        { type: 'trade_payment', name: '付款' },
        { type: 'trade_fapiao', name: '发票' },
        { type: 'trade_credit_letter', name: '信用证' },
        { type: 'trade_credit_letter_repayment', name: '信用证还款' }
      ],
      rules: {
        template_name: [{}],
        groupId: []
      }
    }
  },
  computed: {
    setup () {
      return this.$store.state.shenpi.design
    }
  },
  mounted () {
    this.getCorporation()
  },
  methods: {
    getRule (msg) {
      return [{ required: true, message: msg, trigger: 'blur' }]
    },
    getCorporation () {
      console.log(this.$route.params.code, '<<<<<<<<<<<')
      corporation_list({ id: this.setup.host_corporation_id }).then(rsp => {
        this.hostCorporation = rsp.data.entries
      }).catch(err => this.$message.error('获取企业异常:' + err.response.data))
    },
    addGroup () {
      if (this.newGroup.trim() !== '') {
        updateGroup({ name: this.newGroup.trim() }, 'post').then(rsp => {
          this.$message.success(rsp.data)
          this.getGroups()
        }).catch(err => this.$message.error(err.response.data))
      }
    },
    fetchHostCorporation (query) {
      if (query !== '') {
        this.loading = true
        corporation_list({ name: query }).then(rsp => {
          this.hostCorporation = rsp.data.entries
        }).catch(err => this.$message.error('获取企业异常:' + err.response.data)).finally(() => {
          this.loading = false
        })
      } else {
        this.hostCorporation = []
      }
    },
    selected (select) {
      this.$set(this.setup.settings, this.nowUserSelect, select)
    },
    selectUser (key) {
      this.select = this.setup.settings[key]
      this.nowUserSelect = key
      // this.$refs.orgPicker.show()
    },
    validate () {
      this.$refs.baseSetting.validate()
      const err = []
      if (!this.$isNotEmpty(this.setup.template_name) || this.setup.template_name === '') {
        err.push('表单名称未设置')
      }
      if (!this.$isNotEmpty(this.setup.settings.notify.types)) {
        err.push('所属分类未设置')
      }
      // if (this.setup.settings.notify.types.length === 0) {
      //   err.push('审批所属分类未设置')
      // }
      return err
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-select-dropdown {
  display: none;
}

.icon-select {
  display: flex;
  flex-wrap: wrap;

  i {
    cursor: pointer;
    font-size: large;
    padding: 10px;
    max-width: 38px !important;

    &:hover {
      box-shadow: 0 0 10px 2px #C2C2C2;
    }
  }
}

/deep/ .select-u {
  width: 100%;
}

.base-setup {
  overflow: auto;
  margin: 0 auto;
  width: 600px;
  height: calc(100vh - 105px);
  background: #ffffff;
  margin-top: 10px;
  padding: 15px 20px;

  i:first-child {
    position: relative;
    cursor: pointer;
    font-size: xx-large;
    color: #ffffff;
    border-radius: 10px;
    padding: 10px;
  }

  .change-icon {
    margin-left: 20px;

    span {
      font-size: small;
      color: #7a7a7a;
      margin-right: 15px;
    }

    i {
      cursor: pointer;
      color: #7a7a7a;
      font-size: x-large;
    }
  }

  /deep/ .el-form-item__label {
    padding: 0;
    font-weight: bold;
  }

  /deep/ .el-form-item {
    margin-bottom: 5px;
  }
}

/deep/ .group {
  .el-select {
    width: calc(100% - 130px);
  }

  .el-button {
    margin-left: 10px;
    width: 120px;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #e8e8e8;
}
</style>
